<template>
  <div class="signup-status-wrapper">
    <SignupOverlay>
      <div class="signup-status">
        <svg-icon name="signup-success" class="payment-status__icon"></svg-icon>
        <Heading level="4" class="signup-status__title">
          Ви успiшно зареєстровані
        </Heading>
        <div class="signup-status__text">
          Реєстрація за e-mail
          <span class="signup-status__email">{{ getUser.email }}</span>
          пройшла успішно. Ви можете реєструватись в чергу
        </div>
        <SignupControlButtons
          :back-button-text="$t('signup.button.to_main_page')"
          :go-button-text="$t('signup.button.to_new_ticket')"
          @go-back="goToMainPage"
          @go-on="goToNewTicket"
        />
      </div>
    </SignupOverlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SignupOverlay from '@/components/Signup/SignupOverlay.vue';
import SignupControlButtons from '@/components/Signup/SignupControlButtons/SignupControlButtons.vue';
import Heading from '@/elements/Heading/Heading.vue';

export default {
  components: {
    SignupOverlay,
    SignupControlButtons,
    Heading,
  },
  computed: {
    ...mapGetters(['getUser']),
  },
  methods: {
    goToMainPage() {
      this.$router.push({ name: 'MainPage' });
    },
    goToNewTicket() {
      this.$router.push({ name: 'NewTicketCustomsPost' });
    },
  },
};
</script>

<style lang="sass">
.signup-status-wrapper
  .signup-overlay:before
    @media screen and (max-width: 600px)
      height: calc(100% - 224px)

.signup-status
  max-width: 600px
  text-align: center
  margin: 0 auto

  .signup-control-buttons
    margin-top: 150px

    @media screen and (max-width: 600px)
      margin-top: 80px

.signup-status__title.title
  color: var(--primary-color-success)
  margin: 32px 0 24px 0!important

  @media screen and (max-width: 600px)
    margin: 18px 0 12px 0 !important

.signup-status__text
  font-size: 24px
  line-height: 32px
  font-weight: 600

  @media screen and (max-width: 710px)
    font-size: 18px
    line-height: 26px

.signup-status__email
  color: var(--btn-color-blue)

.icon-signup-success
  width: 92px
  height: 75px
  margin-top: 46px

  @media screen and (max-width: 767px)
    margin-top: 0

  @media screen and (max-width: 600px)
    width: 60px
    height: 50px
</style>
